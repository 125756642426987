<template>
  <section class="my-16 flex justify-center w-full">
    <div class="flex w-full justify-center overflow-hidden rounded">
      <div class="rounded bg-gray-50 h-52 w-full" v-if="isServer"></div>
      <a :href="$configAstro.link" class="w-full" v-else>
        <picture class="rounded">
          <source
            :srcset="$configAstro.banner_largo_url_webp"
            type="image/webp"
          />
          <img
            class="object-cover aspect-auto min-h-52 w-full"
            :src="$configAstro.banner_largo_url_jpg"
            alt="Banner con ofertas destacadas"
            v-if="$configAstro.banner_largo_url_jpg"
          />
        </picture>
      </a>
    </div>
  </section>
</template>
<script setup lang="ts">
  import { ref, onMounted } from "@vue/runtime-core";
  import { useStore } from "@nanostores/vue";
  import { configAstro } from "@stores/app";

  const $configAstro = useStore(configAstro);

  const isServer = ref(true);

  onMounted(() => {
    isServer.value = false;
  });
</script>
